import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Loadable from "react-loadable";
//import { Redirect } from '@reach/router';
import { Redirect, Route, HashRouter, Switch } from "react-router-dom";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = Loadable({
  loader: () => import("../../containers/DefaultLayout/DefaultLayout"),
  loading,
});

const LayoutCidades = Loadable({
  loader: () => import("../../containers//DefaultLayout"),
  loading,
});

const LayoutFiscals = Loadable({
  loader: () => import("../../containers/LayoutFiscals/DefaultLayout"),
  loading,
});

const LayoutCitysIntegration = Loadable({
  loader: () => import("../../containers/LayoutCityIntegration/DefaultLayout"),
  loading,
});

class PrivateRouter extends PureComponent {
  render() {
    const { ...rest } = this.props;
    const { user, city } = rest;

    const layoutLogin = () => {
      if (rest.user.username === "admin") {
        return <Route path="/" name="" component={DefaultLayout} />;
      } else if (
        rest.user.username === "belohorizontepdv" ||
        rest.user.username === "belohorizonte"
      ) {
        return <Route path="/" name="" component={LayoutCitysIntegration} />;
      } else {
        if (user.type === "FISCAL")
          return <Route path="/" name="" component={LayoutFiscals} />;
        else return <Route path="/" name="" component={LayoutCidades} />;
      }
    };

    return user && Object.keys(user).length > 0 ? (
      <Route>{layoutLogin}</Route>
    ) : (
      <Redirect to="/login" />
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(PrivateRouter);
