import "react-app-polyfill/ie9"; // For IE 9-11 support
import "react-app-polyfill/ie11"; // For IE 11 support
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ApolloProvider } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";

import { Provider } from "react-redux";
import { init } from "@rematch/core";
import { PersistGate } from "redux-persist/lib/integration/react";
import createRematchPersist, { getPersistor } from "@rematch/persist";
import createLoadingPlugin from "@rematch/loading";
import storageSession from "redux-persist/lib/storage/session";
import models from "./models";

const API_WEBROTATIVODIGITAL = "https://rdh.timob.com.br";
const httpLink = new HttpLink({
  uri: API_WEBROTATIVODIGITAL,
});
const cache = new InMemoryCache();
const client = new ApolloClient({
  link: httpLink,
  cache,
});

const loading = createLoadingPlugin({
  whitelist: [
    "user/signInAsync",
    "order/cancelOrderPdvAsync",
    "order/processPaymentAsync",
    "order/refuseChargebackAsync",
    "order/authorizeChargebackAsync",
    "supervise/signInFiscalAsync",
    "supervise/getFiscalSupervisePhotosAsync",
    "bhtrans/getBalanceBHTransAppAsync",
    "bhtrans/getBalanceBHTransPdvAsync",
  ],
});

const persistPlugin = createRematchPersist({
  whitelist: ["user"],
  storage: storageSession,
  version: 1,
});

const store = init({
  models,
  plugins: [persistPlugin, loading],
});

const persistor = getPersistor();

ReactDOM.render(
  <PersistGate persistor={persistor}>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </Provider>
  </PersistGate>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
